import { useCookies } from 'vue3-cookies';
import CLayerBaseService from './CLayerBaseService';

const cLayerBaseService = new CLayerBaseService();
const { cookies } = useCookies({ secure: true });
export default class CLayerService extends CLayerBaseService {
  getShippingCategories(term) {
    return new Promise((resolve, reject) => {
      this.getClayerAdminToken().then(() => {
        this.clAmdmin.shipping_categories.list().then((response) => {
          resolve(response);
        });
      })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getPriceLists(term) {
    return new Promise((resolve, reject) => {
      this.getClayerAdminToken().then(() => {
        this.clAmdmin.price_lists.list().then((response) => {
          resolve(response);
        });
      })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getStockLocations(term) {
    return new Promise((resolve, reject) => {
      this.getClayerAdminToken()
        .then(() => {
          this.clAmdmin.stock_locations.list().then((response) => {
            resolve(response);
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  createSkus(Data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.cLayerBaseURL}/api/skus/`;
      this.http
        .post(apiEndPoint, Data, {
          headers: {
            // Accept: 'application/json',
            'Content-Type': 'application/vnd.api+json',
            Authorization: `Bearer ${this.getClayerAdminToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  createPrices(Data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.cLayerBaseURL}/api/prices/`;
      this.http
        .post(apiEndPoint, Data, {
          headers: {
            // Accept: 'application/json',
            'Content-Type': 'application/vnd.api+json',
            Authorization: `Bearer ${this.getClayerAdminToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  createStockItems(Data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.cLayerBaseURL}/api/stock_items/`;
      this.http
        .post(apiEndPoint, Data, {
          headers: {
            // Accept: 'application/json',
            'Content-Type': 'application/vnd.api+json',
            Authorization: `Bearer ${this.getClayerAdminToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
