<template>
  <div v-if="error" class="card text-danger text-center border-danger rounded-5 mb-3">
    <div v-if="Array.isArray(errorMsg)" class="card-body">
      <ul v-for="(item, index) in errorMsg" :key="index" class="mb-0">
        <li>{{ item.toUpperCase() }}</li>
      </ul>
    </div>
    <div v-else class="card-body">
      {{ errorMsg.toUpperCase() }}
    </div>
  </div>
</template>

<script lang="js">
/* eslint-disable */
import { computed } from 'vue';

export default {
  name: 'Error',
  props: ['error', 'message'],
  setup(props) {
    const errorMsg = computed(() => {
      const { error, message } = props;
      let Msg;
      if (!error.errors || error.errors.length <= 0) {
        Msg = error.message;
      } else if (typeof error.errors[0] === 'string') {
        Msg = error.errors;
      } else if (error.message?.includes('validation failed')) {
        Msg = error.errors.filter((item) => item.path).map((item) => {
          const path = item.path?.match(/[^.]+$/)?.[0] || item.path;
          return `${path.replace(/[\-\_\|]/g, ' ')} - ${item.msg?.replace(/[\-\_\|]/g, ' ')}`;
        });
        if (Msg.length <= 0) Msg = 'validation failed';
      } else if (typeof error.errors[0] === 'object') {
        const errors = error.errors[0];
        if (errors.detail?.includes('already exists')) {
          Msg = message;
        } else {
          Msg = error.message;
        }
      }
      if (!Msg) {
        Msg = 'something went wrong';
      }
      return Msg;
    });
    return {
      errorMsg,
    };
  },
};
</script>
