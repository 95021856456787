<template>
  <div class="col-md-6">
    <div class="row">
      <label for="" class="form-label">Add Prices</label>
      <div class="col-md-5">
        <select class="form-select" name="" id="" v-model="productPrice.priceLists">
          <option value="">Select Currency</option>
          <option
            v-for="(item, index) in getPriceLists"
            :key="index"
            :value="item"
            :disabled="addPriceLists.find((addedPrice) => addedPrice.id === item.id)"
          >
            {{ item?.name }}
          </option>
        </select>
        <span class="text-danger" v-if="validate.priceLists.$error">
          {{ validate.priceLists.$errors[0].$message }}*
        </span>
        <span class="text-danger" v-else-if="v$.priceLists.$error">
          {{ v$.priceLists.$errors[0].$message }}*
        </span>
      </div>
      <div class="col-md-5">
        <input
          type="number"
          class="form-control"
          min="0"
          oninput="validity.valid||(value='');"
          placeholder="Amount (in Cents)"
          v-model="productPrice.amount"
          v-on:keyup.enter="selectCurrency"
        />
        <span class="input-help">E.g. 9900 for $99.00</span>
        <span class="text-danger" v-if="v$.amount.$error && !validate.priceLists.$error">
          {{ v$.amount.$errors[0].$message }}*
        </span>
      </div>
      <div class="col-md-2">
        <button class="btn btn-primary" @click="selectCurrency()">add</button>
      </div>
      <div class="mt-3" v-if="addPriceLists.length">
        <ul class="mt-3">
          <li class="mb-2" v-for="(item, index) in addPriceLists" :key="index">
            Currency : {{ item.name }} - Amount :
            {{ item.amount }}
            <div class="btn btn-outline-danger" @click="delPriceList(index)">
              <i class="bi bi-trash"></i>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { useStore } from 'vuex';
import {
  ref, computed, reactive, watch,
} from 'vue';
import { required, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

export default {
  name: 'CLayerPriceInput',
  props: ['validate'],
  setup(props, { emit }) {
    const store = useStore();
    const addPriceLists = ref([]);

    store.dispatch('fetchPriceLists');

    const productPrice = reactive({
      amount: '',
      priceLists: '',
    });

    const rules = {
      priceLists: { required: helpers.withMessage('Please select a currency', required) },
      amount: { required: helpers.withMessage('Please Add amount', required) },
    };
    const v$ = useVuelidate(rules, productPrice);

    const getPriceLists = computed(() => store.getters.getPriceLists);

    const selectCurrency = async () => {
      const result = await v$.value.$validate();
      if (!result) return;
      const Data = {
        id: productPrice.priceLists.id,
        name: productPrice.priceLists.name,
        amount: productPrice.amount,
      };
      v$.value.$reset();
      productPrice.priceLists = '';
      productPrice.amount = '';
      addPriceLists.value.push(Data);
      emit('update:addPriceLists', addPriceLists.value);
    };

    const delPriceList = (index) => {
      addPriceLists.value.splice(index, 1);
      emit('update:addPriceLists', addPriceLists.value);
    };

    watch(addPriceLists, (newVal) => {
      emit('update:addPriceLists', newVal);
    });

    return {
      productPrice,
      selectCurrency,
      delPriceList,
      getPriceLists,
      addPriceLists,
      v$,
    };
  },
};
</script>
