import CmsProductService from '@/services/CmsProductService';
import CLayerService from '@/services/CLayerService';
import * as types from './mutation-types';

const ent = require('ent');

const cLayerService = new CLayerService();
const cmsProductService = new CmsProductService();

export const fetchPlaylistGenres = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    cmsProductService
      .getPlaylistGenres(Data)
      .then((response) => {
        commit(types.PLAYLIST_GENRES, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchBrands = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    cmsProductService
      .getBrands(Data)
      .then((response) => {
        commit(types.BRANDS, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchProductType = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    cmsProductService
      .getProductTypes(Data)
      .then((response) => {
        commit(types.PRODUCT_TYPE, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchProductBySku = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    cmsProductService
      .getProductBySku(Data)
      .then((response) => {
        commit(types.PRODUCT_SKU, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchShippingCategories = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    cLayerService
      .getShippingCategories(Data)
      .then((response) => {
        commit(types.SHIPPING_CATEGORIES, response);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchPriceLists = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    cLayerService
      .getPriceLists(Data)
      .then((response) => {
        commit(types.PRICE_LISTS, response);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchStockLocations = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    cLayerService
      .getStockLocations(Data)
      .then((response) => {
        commit(types.STOCK_LOCATIONS, response);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);
export const removeProductBySku = ({ commit }) => {
  commit(types.REMOVE_PRODUCT_SKU);
};
