<template lang="html">
  <DashboardTemplate>
    <div v-if="isLoading"><Loader /></div>
    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <h1><i class="bi bi-bag"></i> Create New Products</h1>
            </div>

            <div class="col-12 bg-white mb-3 pt-3 mt-2 rounded-5 shadow-sm">
              <div class="row justify-content-end">
                <div class="col-auto">
                  <select name="" id="" class="form-select rounded-5" v-model="productSearch.type">
                    <option value="">Select the Product Category</option>
                    <option value="zenshield">ZenShield</option>
                    <option value="storyshield">StoryShield</option>
                    <option value="storyphones">StoryPhones</option>

                    <!-- <option
                      v-for="(type, index) in getProductType.items"
                      :key="index"
                      :value="type.slug"
                    >
                      {{ type.title }}
                    </option> -->
                  </select>
                  <span class="text-danger" v-if="v$.type.$error">
                    {{ v$.type.$errors[0].$message }}*
                  </span>
                </div>
                <div class="col-auto">
                  <label for="inputPassword2" class="visually-hidden">Search</label>
                  <input
                    type="text"
                    class="form-control rounded-5"
                    placeholder="Enter the SKU ( EAN number) of the product"
                    v-model="productSearch.sku"
                  />
                  <span class="text-danger" v-if="v$.sku.$error">
                    {{ v$.sku.$errors[0].$message }}*
                  </span>
                </div>
                <div class="col-auto tooltip-wrap">
                  <button
                    type="submit"
                    class="btn btn-outline-primary mb-3 rounded-5"
                    @click="searchClick()"
                  >
                    <i class="bi bi-search"></i>
                    <span class="tooltip">Search Here</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="successMsg"
            class="card text-success text-center border-success rounded-5 mb-3"
          >
            <div class="card-body">{{ successMsg }}</div>
          </div>
          <div v-if="errorMsg" class="card text-danger text-center border-danger rounded-5 mb-3">
            <div class="card-body">
              {{ errorMsg }}
            </div>
          </div>
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="card-text">
                <div v-if="getProductBySku.items" class="row g-3">
                  <div class="col-md-6">
                    <label for="" class="form-label">Title</label>
                    <!-- <input type="text"  disabled v-model="productCreate.title" /> -->

                    <div v-if="getProductBySku?.items[0]?.name" class="form-control">
                      {{ getProductBySku?.items[0]?.name }}
                    </div>
                    <div v-else class="form-control">{{ getProductBySku?.items[0]?.title }}</div>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">SKU ( EAN number)</label>
                    <!-- <input type="text" class="form-control" disabled v-model="productCreate.sku" /> -->
                    <div class="form-control">{{ getProductBySku?.items[0]?.sku }}</div>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Brand</label>
                    <!-- <input type="text" class="form-control" disabled v-model="productCreate.brand" /> -->
                    <div class="form-control">
                      {{ getProductBySku?.items[0]?.brand.toUpperCase() }}
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Language Code</label>
                    <!-- <input type="text" class="form-control" disabled v-model="productCreate.language" /> -->
                    <div class="form-control">{{ getProductBySku?.items[0]?.language }}</div>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Image</label>
                    <!-- <input type="text" class="form-control" v-model="productCreate.image" /> -->
                    <img
                      :src="getProductBySku?.items[0]?.thumbnail"
                      style="width: 60px"
                      alt="image"
                    />
                  </div>
                  <!-- <div class="col-md-6">
                    <label for="" class="form-label">Title</label>
                    <input type="text" class="form-control" v-model="productCreate.title" />
                  </div> -->
                  <div class="col-md-12">
                    <div class="row">
                      <h5 for="" class="form-label">Select currency and Add amount</h5>

                      <div class="col-md-5">
                        <label for="" class="form-label">Select Currency</label>
                        <select class="form-select" name="" id="" v-model="productPrice.priceLists">
                          <option value="">Select Currency</option>
                          <option v-for="(item, index) in getPriceLists" :key="index" :value="item">
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-5">
                        <label for="" class="form-label">Amount (in Cents)</label>

                        <input type="number" class="form-control" v-model="productPrice.amount" />
                      </div>
                      <div class="col-md-2">
                        <button class="btn btn-primary" @click="selectCurrency()">add</button>
                      </div>
                      <div class="mt-3" v-if="addPriceLists">
                        <ul class="mt-3">
                          <li class="mb-2" v-for="(item, index) in addPriceLists" :key="index">
                            Currency : {{ item.priceList_name }} - Amount :
                            {{ item.priceList_amount }}
                            <div class="btn btn-outline-danger" @click="delPriceList(index)">
                              <i class="bi bi-trash"></i>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="row">
                      <h5 for="" class="form-label">Select Stock Location and Add Quantity</h5>

                      <div class="col-md-5">
                        <label for="" class="form-label">Select Stock Location</label>

                        <select class="form-select" name="" id="" v-model="productStock.location">
                          <option value="">Select Stock Location</option>
                          <option
                            v-for="(item, index) in getStockLocations"
                            :key="index"
                            :value="item"
                          >
                            {{ item.attributes.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-5">
                        <label for="" class="form-label"> Stock Quantity</label>

                        <input type="number" class="form-control" v-model="productStock.quantity" />
                      </div>
                      <div class="col-md-2">
                        <button class="btn btn-primary" @click="selectStockLocation()">add</button>
                      </div>
                      <div v-if="addStockLocation">
                        <ul>
                          <li class="mb-2" v-for="(item, index) in addStockLocation" :key="index">
                            Location : {{ item.stock_name }} - Quantity :
                            {{ item.stock_qty }}
                            <div class="btn btn-outline-danger" @click="delStock(index)">
                              <i class="bi bi-trash"></i>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 text-end">
                    <button class="btn btn-outline-primary" @click="createProduct">
                      create product
                    </button>
                  </div>
                </div>

                <div v-else class="row">
                  <div class="col-md-12 text-center text-danger">
                    Select type and enter Sku and Search
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>
</template>
<script lang="js">
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import {
  ref, computed, onMounted, reactive,
} from 'vue';
import VueMultiselect from 'vue-multiselect';
import {
  required, email, helpers, between,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import VuejsPaginateNext from '@/components/partials/Paginate.vue';

import Loader from '@/components/commons/Loader.vue';
import CmsProductService from '@/services/CmsProductService';
import CLayerService from '@/services/CLayerService';

const cLayerService = new CLayerService();

const cmsProductService = new CmsProductService();

let fileUploadBtn;
export default {

  name: 'About',
  props: [],
  components: {
    Loader,
    DashboardTemplate,
    // VuejsPaginateNext,
    // VueMultiselect,
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isLoading = ref(false);
    const successMsg = ref('');
    const errorMsg = ref('');
    const addPriceLists = ref([]);
    const addStockLocation = ref([]);

    const productSearch = reactive({
      sku: '',
      type: '',

    });
    const productCreate = reactive({
      title: '',
      sku: '',
      image: '',
      brand: '',
      language: '',
      amount: '',
      quantity: '',
      priceLists: '',
      location: '',
    });

    const productPrice = reactive({
      amount: '',
      priceLists: '',
    });

    const productStock = reactive({
      quantity: '',
      location: '',
    });
    onMounted(() => {

    });

    store.dispatch('fetchProductType');
    store.dispatch('fetchShippingCategories');
    store.dispatch('fetchPriceLists');
    store.dispatch('fetchStockLocations');

    const getProductType = computed(() => store.getters.getProductType);
    const getProductBySku = computed(() => store.getters.getProductBySku);
    const getShippingCategories = computed(() => store.getters.getShippingCategories);
    const getPriceLists = computed(() => store.getters.getPriceLists);
    const getStockLocations = computed(() => store.getters.getStockLocations);

    const rules = {
      sku: { required: helpers.withMessage('Please Enter the sku', required) },
      type: { required: helpers.withMessage('Please select the type', required) },
    };

    const v$ = useVuelidate(rules, productSearch);

    const convertImageUrlToFile = async (url) => {
      if (url === null || url === '') {
        productCreate.image = null;
      } else {
        try {
          const response = await fetch(url);
          const blob = await response.blob();
          const file = new File([blob], 'image.png', {
            type: blob.type,
          });
          productCreate.image = file;
        } catch (error) {
          console.error('Error converting image URL to file:', error);
        }
      }
    };
    const searchClick = async () => {
      const result = await v$.value.$validate();
      if (result) {
        isLoading.value = true;

        store.dispatch('fetchProductBySku', productSearch).then((res) => {
          isLoading.value = false;

          if (res?.data?.items[0]?.name) {
            productCreate.title = res?.data?.items[0]?.name;
          } else {
            productCreate.title = res?.data?.items[0]?.title;
          }
          productCreate.sku = res?.data?.items[0]?.sku;
          productCreate.brand = res?.data?.items[0]?.brand;
          convertImageUrlToFile(res?.data?.items[0]?.image);
          productCreate.language = res?.data?.items[0]?.language;
        }).catch((error) => {
          isLoading.value = false;
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          errorMsg.value = 'somthing went wrong';
          console.log(error);
          setTimeout(() => {
            errorMsg.value = '';
          }, 3000);
        });
      }
    };

    const createProduct = async () => {
      isLoading.value = true;
      try {
        let cname = '';
        if (getProductBySku?.value?.items[0]?.name) {
          cname = getProductBySku?.value?.items[0]?.name;
        } else {
          cname = getProductBySku?.value?.items[0]?.title;
        }
        const Data = {
          data: {
            type: 'skus',
            attributes: {
              code: getProductBySku?.value?.items[0]?.sku,
              name: cname,
              image_url: getProductBySku?.value?.items[0]?.image,
            },
            relationships: {
              shipping_category: {
                data: {
                  type: 'shipping_categories',
                  id: getShippingCategories?.value[0]?.id,
                },
              },
            },
          },
        };
        const respones = await cLayerService.createSkus(Data);
        addPriceLists.value.forEach(async (element) => {
          const Data2 = {
            data: {
              type: 'prices',
              attributes: {
                amount_cents: element.priceList_amount,
              },
              relationships: {
                price_list: {
                  data: {
                    type: 'price_lists',
                    id: element.priceList_id,
                  },
                },
                sku: {
                  data: {
                    type: 'skus',
                    id: respones?.data?.data?.id,
                  },
                },
              },
            },
          };
          await cLayerService.createPrices(Data2);
        });
        addStockLocation.value.forEach(async (element) => {
          const Data3 = {
            data: {
              type: 'stock_items',
              attributes: {
                quantity: element.stock_qty,
              },
              relationships: {
                stock_location: {
                  data: {
                    type: 'stock_locations',
                    id: element.stock_id,
                  },
                },
                sku: {
                  data: {
                    type: 'skus',
                    id: respones?.data?.data?.id,
                  },
                },
              },
            },
          };
          await cLayerService.createStockItems(Data3);
        });
        const formData = new FormData();
        formData.append('title', productCreate.title);
        formData.append('sku', productCreate.sku);
        formData.append('image', productCreate.image);
        formData.append('product_category', productSearch.type);
        formData.append('language', productCreate.language);

        cmsProductService.createProducts(formData).then((res4) => {
          store.dispatch('removeProductBySku');
          let msg = '';
          if (productSearch.type === 'zenshield') {
            msg = 'Zenshield Has Been Added Successfully';
          } else if (productSearch.type === 'storyshield') {
            msg = 'StoryShield Has Been Added Successfully';
          } else if (productSearch.type === 'storyphones') {
            msg = 'StoryPhones Has Been Added Successfully';
          }
          successMsg.value = msg;
          addStockLocation.value = [];
          addPriceLists.value = [];
          isLoading.value = false;
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          // errorMsg.value = error?.data?.errors;
          setTimeout(() => {
            successMsg.value = '';
          }, 3000);
        }).catch((error) => {
          isLoading.value = false;
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          // errorMsg.value = error?.data?.errors;
          errorMsg.value = 'somthing went worng';
          setTimeout(() => {
            errorMsg.value = '';
          }, 3000);
        });
      } catch (error) {
        // console.log(error?.respones?.data);
        errorMsg.value = 'somthing went worng';
        setTimeout(() => {
          errorMsg.value = '';
        }, 3000);
      }
    };

    const selectCurrency = (item) => {
      const Data = {
        priceList_id: productPrice.priceLists.id,
        priceList_name: productPrice.priceLists.attributes.name,
        priceList_amount: productPrice.amount,
      };
      addPriceLists.value.push(Data);
    };

    const delPriceList = (index) => {
      addPriceLists.value.splice(index, 1);
    };

    const selectStockLocation = () => {
      const Data = {
        stock_id: productStock.location.id,
        stock_name: productStock.location.attributes.name,
        stock_qty: productStock.quantity,
      };
      addStockLocation.value.push(Data);
    };

    const delStock = (index) => {
      addStockLocation.value.splice(index, 1);
    };

    return {
      isLoading,
      successMsg,
      errorMsg,
      searchClick,
      getProductType,
      productSearch,
      getProductBySku,
      productCreate,
      createProduct,
      v$,
      getShippingCategories,
      getPriceLists,
      getStockLocations,
      selectCurrency,
      addPriceLists,
      delPriceList,
      addStockLocation,
      selectStockLocation,
      delStock,
      productPrice,
      productStock,
    };
  },
};
</script>
