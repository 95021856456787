<template>
  <div class="col-md-6">
    <div class="row">
      <label for="" class="form-label">Add Stocks</label>
      <div class="col-md-5">
        <select class="form-select" name="" id="" v-model="productStock.location">
          <option value="">Select Stock Location</option>
          <option
            v-for="(item, index) in getStockLocations"
            :key="index"
            :value="item"
            :disabled="addStockLocation.find((addedStock) => addedStock.id === item.id)"
          >
            {{ item?.name }}
          </option>
        </select>
        <span class="text-danger" v-if="validate.stockLists.$error">
          {{ validate.stockLists.$errors[0].$message }}*
        </span>
        <span class="text-danger" v-else-if="v$.location.$error">
          {{ v$.location.$errors[0].$message }}*
        </span>
      </div>
      <div class="col-md-5">
        <input
          type="number"
          class="form-control"
          min="0"
          oninput="validity.valid||(value='');"
          placeholder="Stock Quantity"
          v-model="productStock.quantity"
          v-on:keyup.enter="selectStockLocation"
        />
        <span class="text-danger" v-if="v$.quantity.$error && !validate.stockLists.$error">
          {{ v$.quantity.$errors[0].$message }}*
        </span>
      </div>
      <div class="col-md-2">
        <button class="btn btn-primary" @click="selectStockLocation()">add</button>
      </div>
      <div class="mt-3" v-if="addStockLocation.length">
        <ul class="mt-3">
          <li class="mb-2" v-for="(item, index) in addStockLocation" :key="index">
            Location : {{ item.name }} - Quantity :
            {{ item.quantity }}
            <div class="btn btn-outline-danger" @click="delStock(index)">
              <i class="bi bi-trash"></i>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="js">
/* eslint-disable */
import { useStore } from 'vuex';
import {
  ref, computed, reactive, watch,
} from 'vue';
import { required, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

export default {
  name: 'CLayerStockInput',
  props: ['validate'],
  setup(props, {emit}) {
    const store = useStore();
    const addStockLocation = ref([]);

    const productStock = reactive({
      quantity: '',
      location: '',
    });

    const rules = {
      location: { required: helpers.withMessage('Please select a location', required) },
      quantity: { required: helpers.withMessage('Please Add quantity', required) },
    };
    const v$ = useVuelidate(rules, productStock);

    store.dispatch('fetchStockLocations');
    const getStockLocations = computed(() => store.getters.getStockLocations);

    const selectStockLocation = async () => {
      const result = await v$.value.$validate();
      if (!result) return;
      const Data = {
        id: productStock.location.id,
        name: productStock.location.name,
        quantity: productStock.quantity,
      };
      v$.value.$reset();
      productStock.quantity = '';
      productStock.location = '';
      addStockLocation.value.push(Data);
      emit('update:addStockLocation', addStockLocation.value);
    };

    const delStock = (index) => {
      addStockLocation.value.splice(index, 1);
      emit('update:addStockLocation', addStockLocation.value);
    };

    watch(addStockLocation, (newVal) => {
      emit('update:addStockLocation', newVal);
    });
    return {
        productStock,
        selectStockLocation,
        delStock,
        getStockLocations,
        addStockLocation,
        v$,
    };
  },
};
</script>
