<template lang="html">
  <DashboardTemplate>
    <div v-if="isLoading"><Loader /></div>
    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <h1><i class="bi bi-music-note-list"></i> Add New CMS StoryShield</h1>
            </div>
          </div>
          <div
            v-if="successMsg"
            class="card text-success text-center border-success rounded-5 mb-3"
          >
            <div class="card-body">{{ successMsg }}</div>
          </div>
          <ErrorDisplay :error="errorMsg" message="Unable to add Storyshield" />
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="card-text">
                <div class="row g-3">
                  <div class="d-flex justify-content-between align-items-center mb-3">
                    <!-- Language Navigation -->
                    <div class="d-flex">
                      <nav>
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                          <button
                            v-for="(lang, index) in shieldLanguages"
                            :key="index"
                            class="nav-link"
                            :class="{ active: activeLanguage === lang.code }"
                            @click="changeActiveLanguage(lang.code)"
                          >
                            {{ lang.language }}
                          </button>
                        </div>
                      </nav>
                      <button
                        class="btn btn-outline-secondary"
                        data-bs-toggle="modal"
                        data-bs-target="#SelectLocaleModal"
                      >
                        Change
                      </button>
                    </div>
                    <a
                      href="#"
                      v-if="parentLanguage !== activeLanguage"
                      @click="copyDefaultLanguage"
                      >copy default language</a
                    >
                    <!--  -->
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Title</label>
                    <input type="text" class="form-control" v-model="storyUpload.title" />
                    <span class="input-help">Unique name to identify this product</span>
                    <span class="text-danger" v-if="v$.title.$error">
                      {{ v$.title.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Description</label>
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                      class="form-control"
                      v-model="storyUpload.description"
                    ></textarea>
                    <span class="text-danger" v-if="v$.description.$error">
                      {{ v$.description.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Add Chapter Name</label>
                    <div class="d-flex">
                      <input
                        type="text"
                        class="form-control"
                        v-model="addInformation"
                        v-on:keyup.enter="addDescription"
                      />
                      <button class="btn btn-outline-info rounded-5 mx-3" @click="addDescription">
                        <i class="bi bi-plus-lg"></i>
                      </button>
                    </div>
                    <span class="text-danger" v-if="v$.informations.$error">
                      {{ v$.informations.$errors[0].$message }}*
                    </span>
                    <ul class="mt-3">
                      <li v-for="(item, index) in storyUpload.informations" :key="index">
                        <div class="d-flex justify-content-between mt-1">
                          <span>{{ item }}</span>
                          <button
                            class="btn btn-outline-danger rounded-5 mx-3"
                            @click="delDescription(index)"
                          >
                            <i class="bi bi-trash"></i>
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <!-- <div class="col-md-6">
                    <label for="" class="form-label">GTIN</label>
                    <input type="text" class="form-control" v-model="storyUpload.gtin" />
                  </div> -->
                  <div class="col-md-6">
                    <label for="" class="form-label">Available Languages</label>
                    <VueMultiselect
                      v-if="language.items"
                      v-model="storyUpload.languages"
                      placeholder=""
                      label="language"
                      track-by="code"
                      :options="language.items"
                      :multiple="true"
                      :taggable="true"
                    ></VueMultiselect>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">SKU ( EAN number)</label>
                    <input type="text" class="form-control" v-model="storyUpload.sku" />
                    <span class="input-help">Unique code to identify this product</span>
                    <span class="text-danger" v-if="v$.sku.$error">
                      {{ v$.sku.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">PEGI</label>
                    <input
                      type="number"
                      min="0"
                      oninput="validity.valid||(value='');"
                      class="form-control"
                      v-model="storyUpload.pegi"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">AGE</label>
                    <input
                      type="number"
                      min="0"
                      oninput="validity.valid||(value='');"
                      class="form-control"
                      v-model="storyUpload.age"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Duration</label>
                    <input
                      type="number"
                      min="0"
                      oninput="validity.valid||(value='');"
                      class="form-control"
                      v-model="storyUpload.duration"
                    />
                    <span class="input-help">Total duration in seconds</span>
                    <span class="text-danger" v-if="v$.duration.$error">
                      {{ v$.duration.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Genre</label>
                    <select name="" id="" class="form-select" v-model="storyUpload.genre">
                      <option value="">select the genre</option>
                      <option
                        v-for="(genre, index) in getPlaylistGenres.items"
                        :key="index"
                        :value="genre.id"
                      >
                        {{ genre.title }}
                      </option>
                    </select>
                    <span class="text-danger" v-if="v$.genre.$error">
                      {{ v$.genre.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Shipping Category</label>
                    <select
                      name=""
                      id=""
                      class="form-select"
                      v-model="storyUpload.shippingCategory"
                    >
                      <option value="">select the shipping category</option>
                      <option
                        v-for="(category, index) in getShippingCategories"
                        :key="index"
                        :value="category.id"
                      >
                        {{ category.name }}
                      </option>
                    </select>
                    <span class="input-help"
                      >Determines the available shipping options for this Product</span
                    >
                    <span class="text-danger" v-if="v$.shippingCategory.$error">
                      {{ v$.shippingCategory.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Brand</label>
                    <select name="" id="" class="form-select" v-model="storyUpload.brand">
                      <option value="">select the Brand</option>
                      <option
                        v-for="(brand, index) in getBrands.items"
                        :key="index"
                        :value="brand.slug"
                      >
                        {{ brand.name }}
                      </option>
                    </select>
                    <span class="text-danger" v-if="v$.brand.$error">
                      {{ v$.brand.$errors[0].$message }}*
                    </span>
                  </div>
                  <CLayerPriceInput v-model:addPriceLists="storyUpload.priceLists" :validate="v$" />
                  <CLayerStockInput
                    v-model:addStockLocation="storyUpload.stockLists"
                    :validate="v$"
                  />
                  <div class="col-md-6">
                    <div class="upload-block">
                      <label for="" class="form-label">Sample Audio</label>
                      <input
                        type="file"
                        class="form-control-file"
                        id="audio-file"
                        accept="audio/*"
                        hidden="hidden"
                      />
                      <button
                        class="btn btn-primary form-control"
                        type="button"
                        id="custom-button"
                        @click="audioFile()"
                      >
                        <span>Audio <i class="bi bi-upload" aria-hidden="true"></i></span>
                      </button>
                      <div class="mt-4" v-if="audioURL">
                        <audio controls class="w-100">
                          <source :src="audioURL" type="audio/mpeg" />
                        </audio>
                      </div>
                      <span class="text-danger" v-if="v$.audio.$error">
                        {{ v$.audio.$errors[0].$message }}*
                      </span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Image</label>

                    <div class="input-group">
                      <div class="input-group-text me-4" v-if="imageURL">
                        <div v-if="imageURL">
                          <img :src="imageURL" alt="" style="width: 80px" />
                        </div>
                      </div>
                      <div class="upload-block">
                        <input
                          type="file"
                          class="form-control-file"
                          id="image-file"
                          accept="image/*"
                          hidden="hidden"
                        />
                        <button
                          class="btn btn-primary form-control"
                          type="button"
                          id="custom-button"
                          @click="imageFile()"
                        >
                          <span>Image <i class="bi bi-upload" aria-hidden="true"></i></span>
                        </button>
                      </div>
                    </div>
                    <span class="text-danger" v-if="v$.image.$error">
                      {{ v$.image.$errors[0].$message }}*
                    </span>
                  </div>
                </div>
                <div class="container text-end mt-4">
                  <button @click="uploadStory()" class="btn btn-outline-success me-2">
                    Create
                  </button>
                  <!-- <router-link to="/stories" type="button" class="btn btn-outline-danger"
                    >Cancel</router-link
                  > -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>
  <!-- Select Locale Modal -->
  <div
    class="modal fade"
    id="SelectLocaleModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="SelectLocaleLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="SelectLocaleLabel">Translation</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="clearUpload"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-group mb-3">
            <label for="defaultLanguageSelect" class="form-label">Default Language</label>
            <select id="defaultLanguageSelect" class="form-select" v-model="defaultLanguage">
              <option
                v-for="lang in language.items"
                :key="lang.code"
                :value="lang.code"
                :disabled="lang.code === defaultLanguage"
              >
                {{ lang.language }}
              </option>
            </select>
          </div>
          <h7 class="">Select Languages</h7>
          <div v-for="(lang, index) in language.items" :key="index" class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              :id="'lang-' + lang.code"
              :value="lang.code"
              v-model="selectedLanguageCodes"
              :disabled="lang.code === defaultLanguage"
            />
            <label class="form-check-label" :for="'lang-' + lang.code">
              {{ lang.language }}
            </label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="clearUpload"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="updateLanguages"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import {
  ref, computed, onMounted, reactive, watch,
} from 'vue';
import VueMultiselect from 'vue-multiselect';
import { required, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import CmsProductService from '@/services/CmsProductService';
import Loader from '@/components/commons/Loader.vue';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';
import CLayerPriceInput from '@/components/partials/CLayerPriceInput.vue';
import CLayerStockInput from '@/components/partials/CLayerStockInput.vue';

const cmsProductService = new CmsProductService();

let audioFileBtn;
let imageFileBtn;

export default {
  name: 'About',
  props: [],
  components: {
    DashboardTemplate,
    Loader,
    ErrorDisplay,
    CLayerPriceInput,
    CLayerStockInput,
    VueMultiselect,
    // VuejsPaginateNext,
  },
  setup() {
    /* eslint-disable */
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const isLoading = ref(false);
    const imageURL = ref('');
    const audioURL = ref('');
    const storyId = ref('');
    const searchStory = ref('');
    const searchStoryType = ref('');
    const currentOffset = ref(0);
    const perPage = ref(10);
    const perPageStory = ref(0);
    const perPageUser = ref(0);
    const pageCount = ref(0);
    const page = ref(1);
    const routePath = ref('');
    const searchLanguage = ref('');
    const langPerPage = ref(0);
    routePath.value = route.path;
    const owner = ref('');
    const successMsg = ref('');
    const errorMsg = ref('');
    const storyPerPage = ref(0);
    const isbnStories = ref([]);
    const addInformation = ref([]);
    const parentLanguage = ref('en');
    const defaultLanguage = ref(parentLanguage.value);
    const selectedLanguageCodes = ref([parentLanguage.value]);
    const shieldLanguages = ref([{ code: 'en', language: 'English' }]);
    const activeLanguage = ref(parentLanguage.value);
    let translations = [];

    const storyUpload = reactive({
      title: '',
      description: '',
      image: '',
      audio: '',
      language: '',
      gtin: '',
      pegi: '',
      duration: '',
      brand: '',
      sku: '',
      age: '',
      genre: '',
      languages: '',
      informations: [],
      priceLists: [],
      stockLists: [],
      shippingCategory: ''
    });

    const rules = {
      title: { required: helpers.withMessage('Please Enter the Title', required) },
      description: { required: helpers.withMessage('Please Enter the Description', required) },
      image: { required: helpers.withMessage('Please select a Image', required) },
      brand: { required: helpers.withMessage('Please select a Brand', required) },
      genre: { required: helpers.withMessage('Please select a Genre', required) },
      informations: { required: helpers.withMessage('Please Add Chapter Name', required) },
      sku: { required: helpers.withMessage('Please Enter the sku', required) },
      audio: { required: helpers.withMessage('Please select a audio', required) },
      duration: { required: helpers.withMessage('Please Enter the Duration', required) },
      shippingCategory: { required: helpers.withMessage('Please select a shippingCategory', required) },
      priceLists: { required: helpers.withMessage('Please Add Prices', required) },
      stockLists: { required: helpers.withMessage('Please Add Stocks', required) },
    };

    const v$ = useVuelidate(rules, storyUpload, { $scope: false });

    const searchUser = ref('');

    const langData = {
      searchLanguage,
      currentOffset,
      langPerPage,
    };

    store.dispatch('fetchAllLanguages', langData);
    store.dispatch('fetchPlaylistGenres');
    store.dispatch('fetchBrands');
    store.dispatch('fetchShippingCategories');

    const language = computed(() => store.getters.getLanguage);
    const getPlaylistGenres = computed(() => store.getters.getPlaylistGenres);
    const getBrands = computed(() => store.getters.getBrands);
    const getShippingCategories = computed(() => store.getters.getShippingCategories);

    onMounted(() => {
      audioFileBtn = document.getElementById('audio-file');
      imageFileBtn = document.getElementById('image-file');
    });

    const audioListener = () => {
      if (audioFileBtn.value) {
        const file = audioFileBtn.files[0];
        storyUpload.audio = file;
        const blob = new Blob([file], { type: file.type });
        audioURL.value = URL.createObjectURL(blob);
      } else {
        console.log('no file selected');
      }
    };

    const imageListener = () => {
      if (imageFileBtn.value) {
        const file = imageFileBtn.files[0];
        storyUpload.image = file;
        const blob = new Blob([file], { type: file.type });
        imageURL.value = URL.createObjectURL(blob);
      } else {
        console.log('no file selected');
      }
    };

    const audioFile = () => {
      audioFileBtn.click();
      audioFileBtn.addEventListener('change', audioListener);
    };

    const imageFile = () => {
      imageFileBtn.click();
      imageFileBtn.addEventListener('change', imageListener);
    };

    const uploadStory = async () => {
      let result = await v$.value.$validate();
      updateTranslations(activeLanguage.value);
      // Error for incomplete transilation
      const presentLanguages = translations.map(translation => translation.language);
      const missingLanguages = selectedLanguageCodes.value.filter((language) => !presentLanguages.includes(language));
      if (result && missingLanguages.length > 0) {
        result = false;
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        errorMsg.value = { message: `Missing translation of: ${missingLanguages.join(', ')}.` }
        setTimeout(() => {
          errorMsg.value = '';
        }, 3000);
      }
      if (result) {
        isLoading.value = true;
        const formData = new FormData();
        translations.forEach((translation) => {
          formData.append('translations[]', JSON.stringify(translation));
        })
        formData.append('sku', storyUpload.sku);
        formData.append('is_empty', false);
        ['age', 'duration', 'pegi'].forEach(field => {
            if (storyUpload[field]) {
                formData.append(field, storyUpload[field]);
            }
        });
        formData.append('genre', storyUpload.genre);
        formData.append('brand', storyUpload.brand);
        formData.append('is_new', true);
        formData.append('availability_status', 'available');
        formData.append('sample_audio', storyUpload.audio);
        formData.append('image', storyUpload.image);
        formData.append('tracks', storyUpload.informations.length);
        // formData.append('gtin', storyUpload.gtin);
        if ( storyUpload.languages) {
          const language_string = storyUpload.languages.map((obj) => obj.language).join(', ');
          formData.append('languages', language_string);
        }
        formData.append('parent_language', parentLanguage.value);
        // COMMERCE
        formData.append('shipping_category', storyUpload.shippingCategory);
        storyUpload.priceLists.forEach((price) => {
          formData.append('prices[]', JSON.stringify(price));
        })
        storyUpload.stockLists.forEach((stock) => {
          formData.append('stocks[]', JSON.stringify(stock));
        })

        cmsProductService.createCmsStoryshields(formData).then((response) => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            successMsg.value = 'StoryShield Has Been Added Successfully';
            isLoading.value = false;
            setTimeout(() => {
              successMsg.value = '';
            }, 3000);
        }).catch((error) => {
            isLoading.value = false;
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            errorMsg.value = error?.data;
            setTimeout(() => {
              errorMsg.value = '';
            }, 3000);
        });
      }
    };
    const addDescription = () => {
      storyUpload.informations.push(addInformation.value);
      setTimeout(() => {
        addInformation.value = '';
      }, 0);
    };
    const delDescription = (index) => {
      storyUpload.informations.splice(index, 1);
    };

    const updateDefaultLanguage = (newLangCode, oldLangCode) => {
      // remove current parent from selectedLanguageCodes
      const index = selectedLanguageCodes.value.indexOf(oldLangCode);
      if (index > -1) {
        selectedLanguageCodes.value.splice(index, 1);
      }
      selectedLanguageCodes.value.push(newLangCode);
    };
    watch(defaultLanguage, updateDefaultLanguage);

    const updateLanguages = () => {
      if (parentLanguage.value !== defaultLanguage.value) {
        translations = translations.filter(obj => obj.language !== parentLanguage.value);
        // update parent
        parentLanguage.value = defaultLanguage.value;
        activeLanguage.value = defaultLanguage.value;
      }
      // update shieldLanguages
      const languages = language.value;
      const newLanguages = languages.items.filter((lang) => selectedLanguageCodes.value.includes(lang.code));
      shieldLanguages.value = newLanguages.sort((a, b) => {
        if (a.code === parentLanguage.value) return -1;
        if (b.code === parentLanguage.value) return 1;
        return 0;
      })
    }

    const clearUpload = () => {
      defaultLanguage.value = parentLanguage.value;
      selectedLanguageCodes.value = shieldLanguages.value.map((lang)=> lang.code);
    };

    const updateTranslations = (currentLanguage) => {
      const istranslation = translations.findIndex((obj)=> obj.language === currentLanguage)
      if (istranslation !== -1){
        // Update language transilation
        translations[istranslation].title = storyUpload.title;
        translations[istranslation].description = storyUpload.description;
        translations[istranslation].informations = storyUpload.informations;
      } else {
        // Insert language transilation
        const newTransilation = {
            title: storyUpload.title,
            description: storyUpload.description,
            language: currentLanguage,
            informations: storyUpload.informations
        }
        translations.push(newTransilation);
      }
    };

    const changeActiveLanguage = async (langCode) => {
      const result = await v$.value.$validate();
      if (result) {
        // Save current language transilation
        updateTranslations(activeLanguage.value);
        // Update language
        activeLanguage.value = langCode;
        const istranslation = translations.find((obj)=> obj.language === activeLanguage.value)
        // Update state values for new language
        if (istranslation){
          storyUpload.title = istranslation.title;
          storyUpload.description = istranslation.description;
          storyUpload.informations = istranslation.informations;
        } else {
          storyUpload.title = '';
          storyUpload.description = '';
          storyUpload.informations = [];
        }
      };
    };
    const copyDefaultLanguage = () => {
      const defaultLangTrans = translations.find((singleLang) =>(
        singleLang.language === parentLanguage.value
      ));
      storyUpload.title = defaultLangTrans.title;
      storyUpload.description = defaultLangTrans.description;
      storyUpload.informations = defaultLangTrans.informations;
    };
    return {
      store,
      audioFile,
      imageFile,
      storyUpload,
      uploadStory,
      isLoading,
      imageURL,
      audioURL,
      storyId,
      searchStory,
      currentOffset,
      perPage,
      pageCount,
      page,
      language,
      routePath,
      owner,
      successMsg,
      errorMsg,
      v$,
      isbnStories,
      addInformation,
      getPlaylistGenres,
      getBrands,
      addDescription,
      delDescription,
      shieldLanguages,
      defaultLanguage,
      updateLanguages,
      selectedLanguageCodes,
      clearUpload,
      parentLanguage,
      activeLanguage,
      changeActiveLanguage,
      getShippingCategories,
      copyDefaultLanguage,
    };
  },

};
</script>
